import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  background-color: #111E12; // Adjust based on the design background color
  flex-direction: column;
  font-family: 'Montserrat';

  min-height: 100vh;
  padding: 20px;
`;

export const Subcontainer = styled.div`
  display: flex;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
  flex-direction: column;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const Logo = styled.h1`
  font-size: 2em;
  color: #fff;
  margin-right: 20px;

  img {
    height: 60px;
  }

  @media (max-width: 768px) {
    img {
      height: 30px; // Adjust this value as needed
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  gap: 5px;

  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export const NavBarLink = styled.a`
  color: white;
  font-family: 'Montserrat';
  margin-bottom: 0;
  letter-spacing: -0.04em;
  font-size: 18px;

  :hover {
    color: #1BB78A;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const ContentArea = styled.div`
  flex: 1;
  margin-right: 20px; // Give some space between content and sidebar
`;

export const SubscriptionCard = styled.div`
  width: 100%;
  max-width: 450px; // Adjust width as needed
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.h1`
  margin-bottom: 60px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;

`;

export const Subtitle = styled.h2`
`;

export const EventList = styled.div`
  list-style: none;
  margin-bottom: 20px;
`;

export const EventsItem = styled.h4`
font-family: 'Montserrat';
font-weight: normal;
margin-bottom: 0;
margin-top: 0px;
display: flex; // Add this
align-items: center; // Add this
line-height: 0.8;
`;

export const InvoiceList = styled.div`
  margin-top: 20px;
`;

export const SubscriptionTitle = styled.h1`
  font-size: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  color: #111E12;
  letter-spacing: -0.05em;
  text-align: center;
`;

export const SubscriptionSubtitle = styled.h3`
  font-size: 30px;
  font-family: 'Montserrat';
  font-weight: normal;
  margin-bottom: 0;
  color: #111E12;
  letter-spacing: -0.05em;
  text-align: center;
`;

export const SubscriptionStatus = styled.h3`
  font-size: 25px;
  font-family: 'Montserrat';
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 0;
  color: #111E12;
  letter-spacing: -0.05em;
  text-align: center;
`;

export const CancelButton = styled.button`
  background-color: #111E12; // Green button
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  width: 80%;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const FreezeButton = styled.button`
  background-color: #350F87; // Green button
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  width: 80%;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${props => props.filter};
`
export const ModalButton = styled.button`
  background-color: white; // Green button
  color: #111E12;
  border: none;
  padding: 15px;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 10px;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const SubscriptionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  gap: 1em;
`;

export const PurchaseCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  gap: 1em;
  background-color: #111E12;
  color: white;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1em;
`;

export const Module = styled.div`
  background-color: white;
  padding: 20px;
  color: #111E12;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;


export const SectionContainer = styled.div`
  top: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: auto;
  margin-top: 0px;
  gap: 10px;

  flex-direction: column;
  `;

export const ModulesContainer = styled.div`
  top: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: auto;
  margin-top: 0px;
  gap: 20px;

  flex-direction: column;
  `;


export const Sections = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  background-color: #111E12;
  width: 530px;
  height: 70px;
  border-radius: 10px;
  color: white;

  :hover {
    background-color: #350F87;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SectionNumber = styled.div`
  width: 120px;
  height: 100%;
  color: white;
  text-align: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  border-right: 3px solid white;

  display: flex;
  justify-content: center;
`;

export const SectionName = styled.div`
  font-size: 22px;
  width: 80%;
  letter-spacing: -0.02em;
  font-weight: bold;  

  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
`;

export const SubSectionContainer = styled.div`
  top: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: auto;
  margin-top: 0px;
  gap: 5px;
  padding-left: 20px;

  flex-direction: column;
  `;

  export const SubSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  background-color: #4D5E4F;
  width: 510px;
  height: 50px;
  border-radius: 10px;
  color: white;

  :hover {
    background-color: #350F87;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SubSectionNumber = styled.div`
  width: 19%;
  height: 100%;
  font-size: 30px;
  color: white;
  text-align: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  border-right: 3px solid white;

  display: flex;
  justify-content: center;
`;

export const SubSectionName = styled.div`
  font-size: 18px;
  width: 80%;
  letter-spacing: -0.02em;
  text-align: left;

  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`;