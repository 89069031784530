import styled from "styled-components";

export const AddCardContainer = styled.div`
  width: 32%;
  min-width: 350px;
  height: 140px;
  border: 1px solid rgba(0,0,0,0.08);
  margin-bottom: 1.5em;
  background: #111e12;
  border-radius: 5px;
  display: block;
  cursor: pointer;
  text-color: white;

  @media only screen and (max-width: 1200px) {
    width: 49%;
    min-width: 0px;
  }

  @media only screen and (max-width: 900px) {
      width: 100%;
      min-width: 0px;
  }

`

export const AddCardBody = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;`

export const PlusIcon = styled.a`
  color: white;
`

export const AddText = styled.a`
  color: white;
`

export const AddBookieContainer = styled.div`
  display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
  
  a {
  color: #E9FD8C;
  }

  :hover > a {
    font-weight: bold;
    text-decoration: none;

  }
`
