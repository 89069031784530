import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  font-family: 'Montserrat';

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }

`;

export const BookieInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: fit-content;
  margin: auto;
  margin-top: 0px;
  align-items: center;
  background-color: #1C2D1C;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
  }

  .react-datepicker-wrapper{
    width: 100%;
  }

  .form-group{
    text-align: left;
    max-width: 350px;
  }
`

export const Button = styled.button`
  background-color: #E9FD8C;
  color: #1C2D1C;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`