import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";

export const BetCardContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top:  ${props => props.pinned ? '15px' : '5px'};
  background: rgba(113, 107, 107, 0.08);
  border: ${props => props.pinned ? '1px solid white' : ''};
`

export const MatchOverview = styled.div`
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 10px;

  .settings {
    margin-left: 5px;
  }

  .btn {
    padding: 5px;
    border: 1px solid #052D23;
  }

  .dropdown-toggle {
    padding: 5px;
    
    :hover, :focus, :active {
      cursor: pointer;
      color: #E9FD8C;
      background-color: #052D23;
      border: 1px solid #052D23;
    }
  }
`

export const MatchStatusOverview = styled.div`
  display: flex;
  padding-left: 45px;
  padding-bottom: 10px;
  margin-top: 5px;
  flex-direction: column;
  gap: 10px;
  
  .settings {
    margin-left: 5px;
  }

  .btn {
    padding: 4px;
  }
`

export const Match = styled.div`
  padding-top: 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const FlexContainer = styled.div`
  padding-top: 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-right: 10px;
`

export const EventTextContainer = styled.div`
  @media (max-width: 1208px) {
    flex-direction: column;
    margin-left: 0px;
  }
`;

export const DivisionContainer = styled.div`
  padding-top: 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const ProfitBadgeText = styled.span`
  font-size: medium;
  margin-left: 5px;

  @media (max-width: 450px) {
    font-size: 15px;
  }
`

export const RatingBadgeText = styled.span`
  font-size: medium;
  margin-left: 5px;
`

export const MiddleBadge = styled.span`
  font-size: medium;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0px;
  display: flex;
  background: rgba(227, 111, 0, 0.46);
  border: 1px solid orange;
`

export const MiddleBadgeText = styled.div`
  width: 60%;
  color: white;
  padding: 3px;
  border-radius: 5px;
`

export const MiddleBadgeValue = styled.div`
  width: 40%;
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid orange;

`

export const StatusBadgeText = styled.div`
  font-size: medium;
  margin-left: 5px;
  margin-top: 5px;
  padding: 3px;
  white-space: normal; /* Allows text to wrap to the next line */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate overflowed text */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  display: -webkit-box; /* Required for line clamping */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical; /* Required for line clamping */

  background-color: #B8DCEF;
  color: #111e12;
`

export const ElapsedTimeText = styled.span`
  margin-top: 5px;
  font-size: medium;
  text-align: center;
`

export const EventNameText = styled.span`
  :hover {
    cursor: pointer;
    color: #E9FD8C;
  }
`

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: invert(100%) sepia(21%) saturate(0%) hue-rotate(4deg) brightness(108%) contrast(101%);
`

export const BetButton = styled.button`
  position: absolute;
  right: 10px;
`

export const BetDate = styled.div`
    display: flex;
    padding-left: 20px;
    align-items: center;
    margin-top: 5px;

    img {
    height: 17px;
    margin-right: 5px;
    }
`

export const ClockIcon = styled.i`
  margin-right: 5px;
`

export const BetCardTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1px solid #324436;
  border-radius: 5px;

  .firstRow {
    flex: 0 0 80px;
    justify-content: center;
    width: 130px;
    margin-right: 7px;
    padding-top: 4px;
    padding-bottom: 4px;

    @media (max-width: 1208px) {
      width: 100px;
    }

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  :hover {
    cursor: pointer;
    border: ${props => !props.pinned ? '1px solid white' : ''};
  }
`

export const Column = styled.div`
  height: 'auto';
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 60px;

`

export const PinColumn = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 0;
`

export const BetCardTitle = styled.div`
  display: flex;
  justify-content: center;
  height: 25px;
  font-size: 15px;
  font-weight: 400;
`

export const BetCardRow = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;

  background-color: ${props => props.idx === 1 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};

  font-size: 14px;
  font-weight: 300;
  flex-grow: ${props => props.small ? 1 : 0};

  p {
    margin-bottom: 0px;
  }

  .status {
    margin-left: 5px;
  }

  .status-circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 3px;
  }

  a:link {
    color: white;
    font-weight: bold;
  }

  a:visited {
    color: white;
  }

  :nth-child(1){
    padding-bottom: 5px;
  }

  :nth-child(2){
    padding-bottom: 5px;
  }
`

export const BookieName = styled.a`
  text-transform: capitalize;
  color: rgb(218, 255, 153);
  font-weight: bold;
  font-size: 16px;

  :hover {
    text-decoration: none;
    color: #e9fd8c;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`

export const BookieEventName = styled.a`
  text-transform: capitalize;
  color: white;
  font-weight: normal;
  font-size: 12px;
  margin-top: -3px;
  text-align: center;
  margin-left: 4px;
  ${props => props.visible ? '' : 'visibility: hidden;'};
  ${props => props.visible ? '' : 'height: 3px;'}

  :hover {
    text-decoration: none;
    color: #E9FD8C;
  }

`

export const BetName = styled.a`
  color: white;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    color: ${props => props.href ? 'yellow' : 'white'} !important;
    text-decoration: none;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

export const BetOdds = styled.a`
  color: ${props => props.color};
  font-size: 18px;

  :hover {
    color: ${props => props.color};
    text-decoration: none;
  }

  @media (max-width: 450px) {
    font-size: 15px;
  }
`

export const BetStake = styled.a`
  color: white;
  font-size: 18px;

  :hover {
    color: white;
    text-decoration: none;

    .fa-copy {
      color: #1BB78A;
    }
  }

  @media (max-width: 450px) {
    font-size: 15px;
  }

`

export const ProfitColumn = styled.div``

export const Profit = styled.div`
  display: flex;
  justify-content: center;
`

export const BetCardProfit = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
`

export const RowContentContainer = styled.div`
  height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
  gap: 0px;

  .bookie {
    display: flex;
    flex-direction: column;
    height: 50%;
    
    .bookie-odds {
      display: flex;
      align-items: center;
    }
  }
`

export const ReportButton = styled.div`
  text-align: center;
  margin-bottom: 5px;

  :hover {
    cursor: pointer;
    color: #E9FD8C;
  }
`
export const PinnedIcon = styled.div`
  margin-left: 10px;

  :hover {
    color: #1BB78A;
    cursor: pointer;
  }
`

export const DeletedBadge = styled.div`
  background-color: #A33;
  padding: 5px;
  margin-left: 5px;
  margin-bottom: 0px;
`
export const LeagueNameText = styled.span`
  margin-right: 5px;
  color: #758b77;
`
export const OverTimeBadge = styled.span`
  font-size: 12px;
  background-color: ${props => props.overTimeIncluded ? 'rgba(10, 203, 10, 0.1)' : 'rgba(152, 72, 72, 0.1)'};
  border: ${props => props.overTimeIncluded ? '1px solid green' : '1px solid red'};
  margin-left: 5px;
  margin-bottom: 3px;
`

export const BetBadge = styled.span`
  font-size: 11px;
  background-color: rgba(52, 74, 60, 0.3);
  border: 1px solid black;
  padding: 2px 2px 0px 2px;
  border-radius: 2px;

  @media (max-width: 450px) {
    font-size: 9px;
  }
`

export const StartTimeBadge = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: rgba(40, 162, 27, 0.2);
`

export const ScoreBadge = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: rgb(219, 215, 255);
  color: #111e12;
`

export const SubScoreBadge = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: #008394;
  color: white;
`

export const StatusBadge = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: #ff6b66;
  color: #111e12;
`

export const SecondsSinceLastUpdateText = styled.a`
  font-size: medium;
  color: #ff6969;
  
  :hover {
    text-decoration: none;
    color: #ff6969;
  }
`

export const CircleButton = styled(Button)`
  margin-left: 5px;
  border-radius: 30px;
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  width: 18px;
  padding: 0;
`;

export const SelectedBookieText = styled.div`
  font-weight: bold;
  font-size: 16px;
  background-color: #e9fd8c;
  color: #111e12;
  padding: 5px;
  border-radius: 5px;
`

export const AverageText = styled.div`
  font-weight: bold;
  font-size: 16px;
  background-color: white;
  color: #111e12;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px;
`

export const AlternativeBookieText = styled.div`
  font-size: 14px;
`

export const CalculatorButton = styled.button`
  font-weight: bold;
  font-size: medium;
  margin-left: 5px;
  width: 40px;
  background-color: white;
  color: #1d2d1d;
    // padding: 7px 13px;

  :hover {
    cursor: pointer;
    color: #E9FD8C;
    background-color: #052D23;
  }
`

export const ReturnInput = styled.input`
  font-weight: bold;
  font-size: medium;
  height: 26px;
  width: 70px;
  margin-left: 5px;
`

export const GoalIconContainer = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: red;
  width: auto;

  > b {
    margin-left: 5px;
    color: white;

    @keyframes slowlyAppear {
      0% {
        opacity: 0;
      }
      35% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  
   animation: slowlyAppear 2s infinite;  
    }
  
`

export const GoalIcon = styled.img`
  height: 13px;
  
  @keyframes slowlyAppear {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

 animation: slowlyAppear 2s infinite;  
`

export const InputStakeContainer = styled.div`
  display: flex;
  background-color: white;
  padding: 5px;
  corner-radius: 5px;
  color: #111e12;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  flex-wrap: wrap;

  @media (max-width: 750px) {
    margin-left: -10px;
  }
`

export const StakeButton = styled(Button)`
  width: 50px;
  height: 37px;
  margin-left: 5px;

  :hover {
    background-color: #e9fd8c;
    color: #111e12;
  }
`;

export const PlaceBetButton = styled(Button)`
  width: 50px;
  height: 37px;
  margin-left: 5px;
  background-color: #e9fd8c;
  color: #111e12;

  :hover {
    background-color: #e9fd8c;
    color: #007053;
    border: 3px solid #007053;
  }
`

export const NavigateToArbButton = styled(Button)`
  width: 50px;
  height: 37px;
  margin-left: 5px;
  background-color: #B8DCEF;
  color: #111e12;

  :hover {
    background-color: #B8DCEF;
    color: #007053;
    border: 3px solid #007053;
  }
`


export const ValuebetButton = styled(Button)`
  width: 50px;
  height: 37px;
  color: white;

  :hover {
    background-color: #e9fd8c;
    color: #007053;
    border: 3px solid #007053;
  }
`

export const SelectBookieContainer = styled.div`
  display: flex;
  background-color: white;
  padding: 5px;
  corner-radius: 5px;
  color: #111e12;
  align-items: center;
  gap: 10px;
  border-radius: 5px;

  @media (max-width: 450px) {
    width: 100%;
    margin-left: -10px;
  }

  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: #B8DCEF;
    border: 3px solid #052D23;
    color: #111e12;
    font-weight: bold;
  }

  .btn-primary:focus, .btn-primary:active, .btn-primary:hover {
    background-color: #E9FD8C;
    border: 0px;
    color: #111e12;
}
`

export const HistoryTooltipContainer = styled(Tooltip)`
  .tooltip-inner {
    max-width: 300px;
    width: 300px;
    background-color: #1d2d1d;
  }
`