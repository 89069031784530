// UserHomePage.js
import React, { useState, useEffect } from "react";
import { NavBarMain } from "..";

import {
  PageContainer,
  ModulesContainer,
  Sections,
  Section,
  SectionNumber,
  SectionName,
  SubSectionContainer,
  Module,
  SectionContainer,
  SubSection,
  SubSectionNumber,
  SubSectionName,
  Subcontainer, 
  Header, 
  Title,
} from './AcademyHome.styled';

// The component itself
export const AcademyHome = ({ history }) => {
  return (
    <PageContainer>
      <NavBarMain currentPage="academy" history={history} />
      <Subcontainer>
        <Header>
          <Title>Bienvenido a BetSmarter Academy</Title>
          <h3>Esta es una introducción al curso. Esperamos que disfrutes del camino.</h3>
        </Header>
        <ModulesContainer>
          <Module>
            <h2>Módulo 1</h2>
            <Sections>
              <SectionContainer>
                <Section onClick={() => history.push('/academy/modules/1/1')}>
                  <SectionNumber><h3>1</h3></SectionNumber>
                  <SectionName>Introducción al Arbitraje Deportivo</SectionName>
                </Section>
                <SubSectionContainer>
                  <SubSection onClick={() => history.push('/academy/modules/1/1')}>
                    <SubSectionNumber><h3>1.1</h3></SubSectionNumber>
                    <SubSectionName>¿Qué es una Surebet o Arbitraje Deportivo?</SubSectionName>
                  </SubSection>
                  <SubSection onClick={() => history.push('/academy/modules/1/1')}>
                    <SubSectionNumber><h3>1.2</h3></SubSectionNumber>
                    <SubSectionName>¿Por qué se forma una Surebet?</SubSectionName>
                  </SubSection>
                  <SubSection onClick={() => history.push('/academy/modules/1/1')}>
                    <SubSectionNumber><h3>1.3</h3></SubSectionNumber>
                    <SubSectionName>Prepartido vs Live</SubSectionName>
                  </SubSection>
                  <SubSection onClick={() => history.push('/academy/modules/1/1')}>
                    <SubSectionNumber><h3>1.4</h3></SubSectionNumber>
                    <SubSectionName>¿Qué es la plataforma BetSmarter?</SubSectionName>
                  </SubSection>
                </SubSectionContainer>
              </SectionContainer>
              <SectionContainer>
                <Section onClick={() => history.push('/academy/modules/1/2')}>
                  <SectionNumber><h3>2</h3></SectionNumber>
                  <SectionName>Conceptos básicos</SectionName>
                </Section>
                <SubSectionContainer>
                  <SubSection onClick={() => history.push('/academy/modules/1/2')}>
                    <SubSectionNumber><h3>2.1</h3></SubSectionNumber>
                    <SubSectionName>Terminología</SubSectionName>
                  </SubSection>
                  <SubSection onClick={() => history.push('/academy/modules/1/2')}>
                    <SubSectionNumber><h3>2.2</h3></SubSectionNumber>
                    <SubSectionName>Cupón y Control de Stake</SubSectionName>
                  </SubSection>
                  <SubSection onClick={() => history.push('/academy/modules/1/2')}>
                    <SubSectionNumber><h3>2.3</h3></SubSectionNumber>
                    <SubSectionName>Tabla de Cuotas</SubSectionName>
                  </SubSection>
                  <SubSection onClick={() => history.push('/academy/modules/1/2')}>
                    <SubSectionNumber><h3>2.4</h3></SubSectionNumber>
                    <SubSectionName>Conociendo los mercados básicos</SubSectionName>
                  </SubSection>
                </SubSectionContainer>
              </SectionContainer>
            </Sections>
          </Module>
        </ModulesContainer>
      </Subcontainer>

    </PageContainer>
  );
};
