import React, { useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { NavBarMain } from "..";
import { SettingsProvider } from "../../contexts";
import { observer } from "mobx-react-lite";
import Form from "react-bootstrap/Form";
import _isEmpty from "lodash/isEmpty";
import _without from "lodash/without";
import { FooterSection } from '../Home/FooterSection/FooterSection';

import "./AvailableBookies.styled";
import {
  EventsContainer,
  Subcontainer,
  Title,
  FilterContainer,
  BookieBadge,
  BookieListContainer,
  ClonesContainer,
  MainBookieContainer,
  Clone2Container,
  SearchContainer,
  BookieSearchContainer,
  CountryContainer,
  NoBookiesContainer,
  NoBookiesIcon,
  NoBookiesText,
} from "./AvailableBookies.styled";
import { useSettings } from "../../hooks";

export const AvailableBookies = (props) => (
  <SettingsProvider type="live" >
      <BookiesContainer {...props} />
  </SettingsProvider>
);


const BookiesContainer = observer(({ history }) => {
  const { t } = useTranslation();
  const { options } = useSettings();
  const [bookiesByCountry, setBookiesByCountry] = useState("*");
  const [searchBookies, setSearchBookies] = useState("");

  const availableCountries = [
    {
      value: "ES",
      label: t('Spain'),
    },
    {
      value: "UK",
      label: t('UK'),
    },
    {
      value: "MX",
      label: "Mexico",
    }
  ]

  function isBookieVisible(bookie, bookiesByCountry) {
    // filter bookies by country
    return (
      bookiesByCountry === "*" ||
      (bookiesByCountry === "O" && !_isEmpty(_without(bookie.countries, "ES"))) ||
      (availableCountries.some(country => country.value === bookiesByCountry) && (bookie.countries.includes(bookiesByCountry) || (bookie.clones || []).some(clone => clone.countries.includes(bookiesByCountry))))
    );
  }

  const availableBookies = useMemo(() => 
  (options?.bookies || [])
  .filter(bookie => !bookie.removed)
  .filter(bookie => 
    (searchBookies.length > 0 ? true : isBookieVisible(bookie, bookiesByCountry)) && 
    (bookie.label.toLowerCase().includes(searchBookies.toLowerCase()) || (bookie.clones || []).some(({ bookie }) => bookie.toLowerCase().includes(searchBookies.toLocaleLowerCase())))
  ), [options.bookies, bookiesByCountry, searchBookies]);

  function WideBadgeComponent({ bookie, label, nonClone }) {
    return (
      <BookieBadge key={bookie} forwardedAs="label" nonClone={nonClone}>
        {label}
      </BookieBadge>
    );
  }
  
  const getCloneFilter = clone => 
    !clone.removed &&
    (searchBookies.length > 0 ? true : (bookiesByCountry === '*' ? true : (clone.countries.includes(bookiesByCountry) || (bookiesByCountry === 'O' && clone.countries.every(country => !availableCountries.map(({ value }) => value).includes(country)))))) &&
    (clone.label.toLowerCase().includes(searchBookies.toLowerCase()));

  return (
    <>
      <EventsContainer>
        <NavBarMain currentPage="bookies" history={history} />
        <Subcontainer>
          <Title>Bookies</Title>
          <FilterContainer>
            <CountryContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-earth-americas" /></Form.Label>
              <Form.Control
                as="select"
                label={t('bookiesByCountry')}
                name="bookiesByCountry"
                onChange={({ target }) => setBookiesByCountry(target.value)}
                value={bookiesByCountry}
                style={{ display: "inline", width: "200px", height: 33.25, marginBottom: 0 }}
              >
                <option value="*">{t('allBookies')}</option>
                {
                  availableCountries.map(({ value, label }) => (
                    <option value={value}>{label}</option>
                  ))
                }
                <option value="O">{t('otherCountries')}</option>
              </Form.Control>
            </CountryContainer>
            <SearchContainer>
              <BookieSearchContainer style={{ marginBottom: 0 }}>
                <Form.Control
                  type="text"
                  placeholder={t('filterBookies')}
                  onChange={({ target }) => setSearchBookies(target.value)}

                />
              </BookieSearchContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-magnifying-glass" /></Form.Label>
            </SearchContainer>
          </FilterContainer>
          {availableBookies.length > 0 ? <BookieListContainer role="group" aria-labelledby="checkbox-group" style={{ marginTop: '10px' }}>
            {availableBookies
              .sort((a, b) => a.label.localeCompare(b.label))
              .map(({ bookie, label, clones }) => {
                return <>
                  <ClonesContainer>
                    <MainBookieContainer>
                      <WideBadgeComponent
                        bookie={bookie}
                        label={label}
                      />
                    </MainBookieContainer>
                    {clones && clones
                      .filter(clone => getCloneFilter(clone, bookie)).length > 0 && <Clone2Container>
                        {
                          clones
                            .filter(clone => getCloneFilter(clone, bookie))
                            .map(clone => {
                              const _bookie = `${bookie}:${clone.bookie}`;
                              return <WideBadgeComponent
                                bookie={_bookie}
                                label={clone.label}
                              />
                            })
                        }</Clone2Container>}

                  </ClonesContainer>
                </>
              })
            }
          </BookieListContainer> :
            <NoBookiesContainer>
              <NoBookiesIcon>
                <i className="fa-solid fa-circle-xmark" />
              </NoBookiesIcon>
              <NoBookiesText>{t('noBookies')}</NoBookiesText>
            </NoBookiesContainer>
          }
        </Subcontainer>
        <FooterSection />
      </EventsContainer>
    </>
  );
})
