import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { parse } from 'qs';
import { checkToken } from "../../repositories/utils";
import { useUsers } from "../../contexts/UsersContext";

export const ActivateAccount = ({ location }) => {
    const { token } = parse(location.search, { ignoreQueryPrefix: true });
    const { mutate } = useUsers();

    useEffect(() => {
        localStorage.setItem('jwt', token);
        mutate();
    }, [])

    return checkToken() ?
        <Redirect to="/home/logged" />:
        <Redirect to="/login" />
}