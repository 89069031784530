import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInterval } from "react-use";
import { observer } from "mobx-react-lite";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Spinner from "react-bootstrap/Spinner";
import _groupBy from "lodash/groupBy";

import { NavBarMain, ArbEventCard, ArbCard, CalculatorCard } from "..";
import {
  UserBetsContainer,
  Container,
  SelectedEvent,
  AllArbsContainer,
  SelectedArbContainer,
  VisitorBadge,
  ReportButton,
  SpinnerWrapper,
  ConfigButton,
} from "./ArbsPre.styled";
import { ConfigureArbsModal } from "../ConfigureArbsModal/ConfigureArbsModal";
import { Button, Alert } from "react-bootstrap";
import { useSettings, useEvents, useMemoPrev, useUser, useReport } from "../../hooks";
import { SettingsProvider } from "../../contexts";
import { createArbsRepository } from "../../repositories/ArbsRepository/Arbs.repository";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { ReportProvider } from "../../contexts";
import { getSubscriptionsForSocket, subscriptionName } from "../../utils/subscriptions";

const fpPromise = FingerprintJS.load({ monitoring: false });

const { getArbsPre, getArbsPreLiquid } = createArbsRepository();

const useCalculator = () => {
  const [inputReturn, setInputReturn] = useState(200);
  const [calculatorSelectedBookie, setCalculatorSelectedBookie] = useState(undefined);
  const [calculatorEnabled, enableCalculator] = useState(false);
  const [mode, setMode] = useState('normal');

  const getBet1 = (arb) => calculatorSelectedBookie && arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) ? arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) : arb.bets[0];
  const getBet2 = (arb) => calculatorSelectedBookie && arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) ? arb.bets.find(bet => bet.bookie !== calculatorSelectedBookie) : arb.bets[1];

  const getReturnForBet1 = arb => (getBet1(arb).odds * inputReturn) - (mode === 'freebet' ? inputReturn : 0);

  const getStakeForBet2 = arb => getReturnForBet1(arb) / getBet2(arb).odds;

  const getStakeForBet = (arb, { bookie, idx }) => bookie === calculatorSelectedBookie || (!calculatorSelectedBookie && idx === 0) ? inputReturn : getStakeForBet2(arb);

  return { inputReturn, setInputReturn, calculatorSelectedBookie, setCalculatorSelectedBookie, calculatorEnabled, enableCalculator, getBet1, getBet2, getReturnForBet1, getStakeForBet, getStakeForBet2, mode, setMode };
};

// Wrap into provider:
// - configuration provider to have access to configuration
// - report provider to have a single modal for all reports
export const ArbsPre = (props) => (
  <SettingsProvider type="pre" >
    <ReportProvider>
      <ArbsContent {...props} />
    </ReportProvider>
  </SettingsProvider>
);

const ArbsContent = observer(({ history }) => {
  const { captureSnapshot } = useEvents();
  const { settings } = useSettings();
  const configuration = settings?.configuration;
  const [eventFiltered, setEventState] = useState(undefined);
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [pinnedArbId, setPinnedArbId] = useState(undefined);
  const { getActiveSubscription, getExtensionConfiguration } = useUser();
  const [subscriptionStatus, setSubscriptionStatus] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [visitorId, setVisitorId] = useState(undefined);
  const [tokenConfiguration, setTokenConfiguration] = useState(undefined);
  const [showVisitorId] = useState(false);
  const { openReportModal } = useReport();
  const [arbs, setArbs] = useState([]);
  const [areArbsLoaded, setAreArbsLoaded] = useState(false);

  const calculator = useCalculator();

  const processFilter = useCallback((filter, arb) => {
    const filterTypes = {
      1: () => filter.data.id === arb.id,
      2: () => filter.data.eventId === arb.match.id,
      3: () =>
        filter.data.eventId === arb.match.id && [arb.bets[0].bookie, arb.bets[1].bookie].includes(filter.data.bookie),
      4: () =>
        filter.data.eventId === arb.match.id &&
        filter.data.bookies.every((bookie) => [arb.bets[0].bookie, arb.bets[1].bookie].includes(bookie)),
    };

    return filterTypes[filter.type] ? filterTypes[filter.type]() : false;
  }, []);

  const checkIfNeedsFiltering = useCallback(
    (arb) => !filters.some((filter) => processFilter(filter, arb)),
    [filters, processFilter]
  );

  const bookiesConfigs = useMemo(() => 
    Object.entries(configuration?.bookiesConfigs ?? {})
      .reduce((t, [bookie, config]) => ({ ...t, [`${bookie.split(':')[0]}`]: config }), {}), 
    [configuration?.bookiesConfigs]);

  const onlyLiquid = useMemo(() =>  configuration?.onlyLiquid, [configuration?.onlyLiquid]); 

  useEffect(() => {
    calculator.setMode(configuration?.mode);
  }, [configuration?.mode]);

  // A function that sorts arbs depending on the configuration
  const arbsSortFunc = useMemo(() => {
    const sortBy = {
      timeAsc: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      timeDes: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      profitDes: (a, b) => configuration?.mode === 'normal' ? a.rawProfit - b.rawProfit : b.rating - a.rating,
      profitAsc: (a, b) => configuration?.mode === 'normal' ? b.rawProfit - a.rawProfit : a.rating - b.rating,
    };

    return sortBy[configuration?.sortBy] ?? sortBy.timeAsc;
  }, [configuration?.sortBy, configuration?.mode]);

  // filter arbs using the selected settings configuration
  const filteredArbs = useMemo(
    () =>
      arbs.filter((arb) => {
        // Apply filters

        const [bookie1, bookie2] = [arb.bets[0].bookie, arb.bets[1].bookie];
        const bookie1Config = bookiesConfigs[bookie1];
        const bookie2Config = bookiesConfigs[bookie2];

        if (!checkIfNeedsFiltering(arb)) return;

        // Only include selected bookies
        if (!arb.bets.every((bet) => configuration.bookies.some(bookie => bookie.includes(bet.bookie)))) return;

        // At least on of the bookies have to be a reference bookie
        if (!arb.bets.some((bet) => configuration.bookiesReference.some(bookie => bookie.includes(bet.bookie)))) return;

        // Get the configuration for this subscription (i.e. pair of bookies)
        const subscription = subscriptionName(arb.bets[0].bookie, arb.bets[1].bookie);
        let subsConfig = configuration.subscriptionConfigs[subscription];
        if (subsConfig === undefined) subsConfig = configuration;

        // Filter overtime mismatch
        if (!([true, "true"].includes(subsConfig.filterOverTimeMismatch) && arb.overTimeMatch)) return;

        // Filter minPercentage
        if (!(100 - arb.rawProfit * 100 >= (parseInt(subsConfig.minPercentage) || 0))) return;

        // Filter maxPercentage
        if (!(100 - arb.rawProfit * 100 <= (parseInt(subsConfig.maxPercentage) || 100))) return;

        // Filter sports
        if (!subsConfig.sports.includes(arb.match?.sport?.name)) return;

        // Filter sports markets
        if (!arb.bets.some((bet) => subsConfig.sportsMarkets[arb.match?.sport?.name]?.includes(bet.marketShortName)))
          return;

        // Filter excluded leagues
        if (
          subsConfig.excludedLeagues?.some((league) =>
            arb.match.league.name.toLowerCase().includes(league.toLowerCase())
          )
        )
          return;

          const oddsInsideFilter = ((bookie1Config ? (bookie1Config.minOdds <= arb.bets[0].odds && bookie1Config.maxOdds >= arb.bets[0].odds) : true) &&
          (bookie2Config ? (bookie2Config.minOdds <= arb.bets[1].odds && bookie2Config.maxOdds >= arb.bets[1].odds) : true));

        if(!oddsInsideFilter) return;

        // If all filters passed, keep the arb
        return true;
      }),
    [arbs, checkIfNeedsFiltering, eventFiltered, configuration]
  );

  const pinnedArb = useMemoPrev((prevPinnedArb) => {
    if (pinnedArbId === undefined) return undefined;
    let arb = arbs.find(arb => arb.id === pinnedArbId);
    if (!arb && prevPinnedArb) return {...prevPinnedArb, deleted: true};
    return arb;
  }, [pinnedArbId, arbs])

  const setPinnedArb = useCallback((arb) => {
    calculator.setCalculatorSelectedBookie(undefined);
    return setPinnedArbId(arb?.id)
  }, [setPinnedArbId]);

  // array of matches obtained from grouping all arbs by its match id
  const matches = useMemo(() => {
    const matchesHash = _groupBy(filteredArbs, (arb) => arb.match.id);
    const _matches = Object.entries(matchesHash).map(([matchId, matchArbs]) => ({
      id: parseInt(matchId),
      arbs: matchArbs.sort(arbsSortFunc),
    }));
    return _matches.filter(({ id }) => (eventFiltered ? eventFiltered.id === id : true)).sort((matchA, matchB) => arbsSortFunc(matchA.arbs[0], matchB.arbs[0]));
  }, [filteredArbs]);

  const matchesForPinnedArb = useMemo(
    () => (pinnedArb ? matches.filter((match) => match.id === pinnedArb.match.id) : []),
    [matches, pinnedArb]
  );

  const createReport = useCallback(async (event) => {
    event.stopPropagation();
    openReportModal();
  }, [openReportModal]);

  const setEvent = useCallback((event) => {
    if (!eventFiltered) setEventState(event);
  }, []);

  const addFilter = useCallback((filter) => setFilters((previousFilters) => [...previousFilters, filter]), []);

  // Inital stuff to do after first render
  useEffect(() => {
    checkIfTokenStillValid(history);

    // Use a function to use async/await syntax inside useEffect
    const onMounted = async () => {
      await Promise.all([
        getActiveSubscription().then((subscriptionStatus) => setSubscriptionStatus(subscriptionStatus)),
        fpPromise.then((fp) => fp.get()).then((result) => setVisitorId(result.visitorId)),
        getExtensionConfiguration().then((response) => setTokenConfiguration(response)),
      ]);
    };
    onMounted();

  }, []);

  useEffect(() => {
    let timeoutId;
    let stopped = false;

    const processUpdates = () => {
      // Periodically get new arbs
        // Do nothing until settings are loaded
        if (!settings) return;
    
        const { configuration } = settings;
        const { minPercentage, maxPercentage } = configuration;
        const channels = getSubscriptionsForSocket(configuration);
        const fn = onlyLiquid ? getArbsPreLiquid : getArbsPre;

        fn({ channels, minPercentage, maxPercentage }).then(newArbs => {
          setAreArbsLoaded(true);
          setArbs(newArbs);
          if (!stopped) timeoutId = window.setTimeout(processUpdates, 2000);
        });
      };
    
      processUpdates();

      return () => {
        stopped = true;
        window.clearTimeout(timeoutId);
      };
  }, [settings]); 

  return (
    <>
      <UserBetsContainer>
        <NavBarMain currentPage="pre" type="arbsPre" history={history}/>
        {configuration?.bookiesReference?.length === 0 && (
          <Alert variant="danger" className="customAlert">
            <p>No tienes ninguna bookie de referencia seleccionada. ¡Modifica tu configuración!</p>
          </Alert>
        )}
        <rn-banner />
        <div style={{ display: "flex", alignItems: "center", marginTop: '20px' }}>
          <ConfigureArbsModal show={showConfigurationModal} setVisible={setShowConfigurationModal} />
          <ConfigButton disabled={!configuration} onClick={() => setShowConfigurationModal(true)}>
            Configurar
          </ConfigButton>
          <ReportButton onClick={createReport}>
            <span className="fas fa-exclamation-triangle"></span>
            <a>Reportar</a>
          </ReportButton>
          {showVisitorId && <VisitorBadge>{visitorId}</VisitorBadge>}
        </div>
        {configuration &&
          (!areArbsLoaded ? (
            <SpinnerWrapper>
              <Spinner animation="border" role="loading" />
            </SpinnerWrapper>
          ) : (
            <>
              {eventFiltered && (
                <SelectedEvent>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    style={{ float: "left", marginRight: "5px" }}
                    onClick={() => setEventState(undefined)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  Evento seleccionado:
                  <span className="badge badge-secondary" style={{ fontSize: "medium" }}>
                    {eventFiltered.name}
                  </span>
                </SelectedEvent>
              )}
              <Container>
                <AllArbsContainer pinnedArb={!!pinnedArb}>
                  {matches.map((match) => (
                    <ArbEventCard
                      key={match.id}
                      configuration={configuration}
                      arbs={match.arbs}
                      event={match.arbs[0].event}
                      captureSnapshot={captureSnapshot}
                      setEvent={setEvent}
                      setPinnedArb={setPinnedArb}
                      eventFiltered={eventFiltered}
                      pre={true}
                    />
                  ))}
                </AllArbsContainer>
                {pinnedArb && (
                  <SelectedArbContainer pinnedArb={!!pinnedArb}>
                    <ArbCard
                      arb={pinnedArb}
                      event={pinnedArb.event}
                      captureSnapshot={captureSnapshot}
                      pinned={true}
                      setPinnedArb={setPinnedArb}
                      addFilter={addFilter}
                      calculator={calculator}
                      tokenConfiguration={tokenConfiguration}
                      pre={true}
                    />
                    <CalculatorCard
                      arb={pinnedArb}
                      event={pinnedArb.event}
                      pinned={true}
                      calculator={calculator}
                    />
                    {matchesForPinnedArb.map((match) => (
                      <ArbEventCard
                        key={match.id}
                        configuration={configuration}
                        arbs={match.arbs}
                        event={match.arbs[0].event}
                        captureSnapshot={captureSnapshot}
                        setEvent={setEvent}
                        setPinnedArb={setPinnedArb}
                        eventFiltered={true}
                        pre={true}
                      />
                    ))}
                  </SelectedArbContainer>
                )}
              </Container>
            </>
          ))}
      </UserBetsContainer>
    </>
  );
});
