import React from "react";
import "./ArbEventCard.styled";
import {
  getSportIcon,
  getGoalIcon,
  getSportIconColor,
  getStatusInformation,
  getScoreForArbs,
  getSubScoreForArbs,
  checkIfScoreChanged,
  getBetStatusInformation
} from "../../utils/events";
import {
  BetCardContainer,
  Match,
  FlexContainer,
  MatchOverview,
  SportIcon,
  EventNameText,
  PlacedBetsContainer,
  StatusBadge,
  LeagueNameText,
  ExtraArbsBadge,
  ScoreBadge,
  SubScoreBadge,
  StartTimeBadge,
  GoalIconContainer,
  GoalIcon,
  DivisionContainer,
  EventTextContainer,
  StatusContainer,
  CountryNameText
} from "./ArbEventCard.styled";
import { ArbCard } from "..";

export const ArbEventCard = ({ arbs = [], event, setEvent, setPinnedArb, captureSnapshot, eventFiltered, pre = false, configuration }) => {
  const getDisplayableArbs = () => {
    if (eventFiltered) return arbs;
    if (arbs.length === 0) return [];

    let firstArb;
    if (['timeAsc'].includes(configuration?.sortBy)) {
      const currentTimestamp = new Date().getTime();
      const arbsUpdatedOnTheLastSeconds = arbs.filter(arb => currentTimestamp - arb.version < 5000)
      
      firstArb = arbsUpdatedOnTheLastSeconds.length > 0 ? arbsUpdatedOnTheLastSeconds.sort((a, b) => a.rawProfit - b.rawProfit)[0] : arbs[0];
    } else  if (['timeDes'].includes(configuration?.sortBy)) {
      const firstArbTimestamp = arbs[0].version;
      const arbsUpdatedOnTheLastSeconds = arbs.filter(arb => Math.abs(firstArbTimestamp - arb.version) < 3000)
      
      firstArb = arbsUpdatedOnTheLastSeconds.length > 0 ? arbsUpdatedOnTheLastSeconds.sort((a, b) => a.rawProfit - b.rawProfit)[0] : arbs[0];
    } else {
      firstArb = arbs[0];
    }

    if (getBetStatusInformation(firstArb)) return [firstArb];

    const firstArbWithStatus = arbs.find(arb => arb.id !== firstArb.id && getBetStatusInformation(arb));

    return firstArbWithStatus ? [firstArb, firstArbWithStatus] : [firstArb];
  }

  return (
    <BetCardContainer
      className="card"
      data-sport-name={event.sport?.name}
      onClick={() => setEvent(event)}
      status={event?.status}
      arbStatus={!eventFiltered ? arbs[0]?.status : undefined}
    >
      <MatchOverview>
        <Match>
          <SportIcon src={getSportIcon(event.sport?.name)} filter={getSportIconColor(event.sport?.name)} />
          <EventTextContainer>
            <EventNameText className="event" data-event-name={event?.name}> {event?.name} </EventNameText>
            <DivisionContainer>
              {event?.league?.country && <CountryNameText className="country" data-country-name={event?.league?.country}>{event?.league?.country}</CountryNameText>}
              <LeagueNameText className="league" data-league-name={event?.league?.name}>{event?.league?.name}</LeagueNameText>
            </DivisionContainer>
            {
              <StatusContainer>
                {
                  !pre && <>
                    <ScoreBadge className="badge badge-secondary">{getScoreForArbs(event)} </ScoreBadge>
                    {getSubScoreForArbs(event) && (
                      <SubScoreBadge className="badge badge-secondary">{getSubScoreForArbs(event)}</SubScoreBadge>
                    )}
                    {checkIfScoreChanged(event) &&
                      <GoalIconContainer className="badge badge-secondary">
                        <GoalIcon src={getGoalIcon(event.sport?.name)} />
                        <b>GOAL</b>
                      </GoalIconContainer>
                    }
                    {getStatusInformation(event) && (
                      <StatusBadge
                        className="badge"
                        status={event?.status}
                        arbStatus={!eventFiltered ? arbs[0]?.status : undefined}
                      >
                        {" "}
                        {getStatusInformation(event)}{" "}
                      </StatusBadge>
                    )}
                  </>
                }
                {arbs.length > 1 && (
                  <ExtraArbsBadge className="badge badge-secondary">
                    {" "}
                    +{arbs.length - 1} {arbs.length - 1 > 1 ? "Arbs" : "Arb"}{" "}
                  </ExtraArbsBadge>
                )}
              </StatusContainer>
            }
          </EventTextContainer>
        </Match>
      </MatchOverview>
      { pre && !eventFiltered && 
      <MatchOverview>
        <FlexContainer>
          { event.startedAt && <StartTimeBadge className="badge">{new Date(event.startedAt).toLocaleString()}</StartTimeBadge> }
        </FlexContainer>
      </MatchOverview> 
      }
      <PlacedBetsContainer>
        {getDisplayableArbs().map((arb) => (
          <ArbCard
            key={arb.id}
            arb={arb}
            captureSnapshot={captureSnapshot}
            event={event}
            setEvent={setEvent}
            setPinnedArb={setPinnedArb}
            pre={pre}
          />
        ))}
      </PlacedBetsContainer>
    </BetCardContainer>
  );
};
