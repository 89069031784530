import React , { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import _compact from "lodash/compact";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { HistoryTooltip } from "../HistoryTooltip/HistoryTooltip";
import { useTranslation } from 'react-i18next';

import {
  BetCardContainer, 
  LeagueNameText,
  BetName,
  BetBadge,
  BookieName,
  BetCardRow, BetCardTable, ScoreBadge, StatusBadge, MiddleBadge, MiddleBadgeText, MiddleBadgeValue,
  Column, RowContentContainer,PinColumn, ElapsedTimeText, ProfitBadgeText, StatusBadgeText, ReportButton, Match, MatchOverview, SportIcon, EventNameText, DeletedBadge, OverTimeBadge,
  CircleButton,
  SelectedBookieText,
  AverageText,
  SubScoreBadge,
  BookieEventName,
  MatchStatusOverview,
  StartTimeBadge,
  ReturnInput,
  CalculatorButton,
  GoalIconContainer,
  GoalIcon,
  BetOdds,
  BetStake,
  SecondsSinceLastUpdateText,
  InputStakeContainer,
  SelectBookieContainer,
  RatingBadgeText,
  DivisionContainer,
  FlexContainer,
  EventTextContainer,
  AlternativeBookieText,
  HistoryTooltipContainer,
  StakeButton,
  PlaceBetButton,
  NavigateToArbButton,
  ValuebetButton
} from './ArbCard.styled'
import { getSportIcon, getGoalIcon, getSportIconColor, getScoreForArbs, getSubScoreForArbs, getStatusInformation, checkIfScoreChanged, getBetStatusInformation } from '../../utils/events'
import { useEvents, useReport, useSettings } from "../../hooks";
import { getDuration, getColorForChangedArb, getURL, getBetNameFormatted } from "../../utils";

export const ArbCard = observer(({ arb, event, setPinnedArb, pinned, addFilter, calculator, tokenConfiguration, pre = false, configuration, executeArb, executeValuebet, navigateToArb, subscriptionStatus }) => {
  const [currentTime, setTime] = useState(new Date)
  const [alternativeOdds, setAlternativeOdds] = useState([])
  const [showBookieEventName, setShowBookieEventName] = useState(false);
  const { options: {bookieToLabel}, settings} = useSettings();
  const {openReportModal} = useReport();
  const { getAlternativeOdds } = useEvents();
  const [selectedFirstBetId, setSelectedFirstBetId] = useState(undefined);
  const [clicksOnValuebet, setClicksOnValuebet] = useState({});
  const { t } = useTranslation();

  const cloneBookies = useMemo(() => (
    settings?.configuration?.bookies.filter(bookie => bookie.split(':').length > 1).reduce((acc, bookie) => {
      const [bookieName, cloneName] = bookie.split(':');
      acc[bookieName] = cloneName;
      return acc;
    }, {})
  ), [settings?.configuration?.bookies]);

  const sortedBets = useMemo(() => (
    arb.bets
      .sort((a, b) => a.isOver === undefined || b.isOver === undefined ? 0 : b.isOver - a.isOver)
      .sort((a, b) => a.team === undefined || b.team === undefined ? 0 : a.team - b.team)
  ), [arb.bets]);

  const pinArb = (event) => {
    event.stopPropagation(); 
    if (!pinned) setPinnedArb(arb);
  }

  const onChangeHandler = (e) => {
    const returnTarget = parseInt(e.target.value || 0, 10);

    calculator.setInputReturn(returnTarget)
  }

  const reportArb = async (event) => {
    event.stopPropagation();
    openReportModal({ ...arb, pre, deleted: arb.deleted || false });
  }

  const getAverageOdds = (bet, idx) => {
    const altOdds = alternativeOdds[idx] || bet.alternativeOdds || [];
    const maxDiff = pre ? 60 * 60 * 1000 : 4 * 60 * 1000;
    const filteredAltOdds = altOdds.filter(b => b.active && !b.deleted && (new Date() - new Date(b.updatedOn)) < maxDiff);
    return filteredAltOdds.reduce((t, c) => t + c.odds, 0) / filteredAltOdds.length;
  }

  const getBetHistory = (bet) => [bet, ...(bet.history || []).toReversed()]

  const getOddsDeviation = (bet, idx) => ( 1 - (getAverageOdds(bet, idx) / bet.odds)) * 100;

  const isPlacerUser = useMemo(() => subscriptionStatus?.roles?.includes('placer'), [subscriptionStatus?.roles]);

  const getOddsDeviationText = (bet, idx) => {
    const oddsDeviation = getOddsDeviation(bet, idx);
    if (Number.isNaN(oddsDeviation)) return "";
    return `${oddsDeviation > 0 ? '+' : ''}${oddsDeviation.toFixed(2)}%`;
  };

  // Return when was the bet updated
  const getBetUpdatedOn = (bet) => {
    let betUpdatedOn;
    if (bet.updatedOn) betUpdatedOn = new Date(bet.updatedOn);
    else {
      const altOdd = bet?.alternativeOdds?.find(({ bookie }) => bookie === bet.bookie)
      if (!altOdd) return undefined;
      betUpdatedOn = new Date(altOdd.updatedOn).getTime();
    }
    return getDuration(betUpdatedOn, currentTime);
  }

  const refreshOdds = async (arb, event) => {
    try {
      if (!pinned) return
      const formattedArb = {
        bets: arb.bets.map(bet => ({ shortName: bet.realShortName, periodTypeId: bet.periodTypeId !== 0 ? bet.periodTypeId : undefined, period: bet.period !== 0 ? bet.period : undefined }))
      }
      const response = await getAlternativeOdds(event.id, formattedArb)
      setAlternativeOdds(response.bets.map(({ alternativeOdds }) => alternativeOdds));
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const interval = window.setInterval(() => {
      setTime(new Date())
    }, 1000)

      // Clear interval
      return () => window.clearInterval(interval);
    }, []);

  useEffect(() => {
    setAlternativeOdds([]);
    let interval;
    
    if (!pre && pinned) {
      interval = window.setInterval(() => {
        refreshOdds(arb, event);
      }, 2000)
    }

      // Clear interval
      return () => interval && window.clearInterval(interval);
    }, [event]);

  const renderBetBadge = (bet, idx) => {
    const text = _compact([getBetUpdatedOn(bet), getOddsDeviationText(bet, idx)]).join(" | ");
    if (text === "") return <BetBadge style={{visibility: "hidden"}}>1s</BetBadge>;
    return <BetBadge>{text}</BetBadge>;
  }

  const getSecondsSinceLastScoreUpdate = (event) => {
    const lastScoreUpdate = new Date(event?.score?.updatedAt);
    const currentTime = new Date();
    const difference = currentTime - lastScoreUpdate;
    const seconds = Math.floor(difference / 1000);
    return seconds;
  }

  const getSecondsSinceLastActionUpdate = (event) => {
    const lastScoreUpdate = event?.action?.updatedAt ? new Date(event?.action?.updatedAt) : new Date(event?.time?.timestamp);
    const currentTime = new Date();
    const difference = currentTime - lastScoreUpdate;
    const seconds = Math.floor(difference / 1000);
    return seconds;
  }

  const handleValuebetClick = bet => {
    setClicksOnValuebet(prevClicks => {
      const betId = `${arb.version}_${bet.id}`;
      const updatedClicks = { ...prevClicks, [betId]: (prevClicks[betId] || 0) + 1 };
  
      // If this is the third click, call executeValuebet and reset the count
      if (updatedClicks[betId] === 3) {
        executeValuebet({ targetWin: calculator.inputReturn, selectedBet: bet });
        updatedClicks[betId] = 0;
      }
  
      return updatedClicks;
    });
  }

  const getValuebetButtonColor = bet => {
    const betId = `${arb.version}_${bet.id}`;
    if (!clicksOnValuebet[betId] || clicksOnValuebet[betId] === 0) return '#1d2d1d';
    else if (clicksOnValuebet[betId] === 1) return '#d83737';
    else if (clicksOnValuebet[betId] === 2) return '#6ddb6d';
    return 'blue';
  }

  return (
    <BetCardContainer className={pinned ? "card pinned" : "card" } data={pinned ? JSON.stringify(arb) : undefined} data-configuration={pinned ? JSON.stringify(tokenConfiguration) : undefined} pinned={pinned} onClick={pinArb}>
      {pinned && <> 
      <MatchOverview>
          <Match>
            <SportIcon className="sport" data-sport-name={arb.match?.sport?.name} src={getSportIcon(arb.match?.sport?.name)} filter={getSportIconColor(arb.match?.sport?.name)}/>
            <EventTextContainer>
              <EventNameText className="event" data-event-name={arb.match?.name}> {arb.match?.name} </EventNameText>
              <DivisionContainer>
                { arb.match?.league?.country && <LeagueNameText className="country" data-country-name={arb.match?.league?.country}>{arb.match?.league?.country} | </LeagueNameText> }
                <LeagueNameText className="league" data-league-name={arb.match?.league?.name}>{arb.match?.league?.name} </LeagueNameText>
              </DivisionContainer>
            </EventTextContainer>
            { arb.deleted && 
              <DeletedBadge className="alert">
                {t("removed")}
              </DeletedBadge>
            }
            <Dropdown className="settings">
              <Dropdown.Toggle variant="secondary" >
                <i className="fas fa-cog"/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1" onSelect={() => addFilter({ type: 1, data: { id: arb.id }})}>
                  Ocultar Arb
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" onSelect={() => addFilter({ type: 2, data: { eventId: arb.match.id }})}>
                  Ocultar Evento
                </Dropdown.Item>
                <Dropdown.Item eventKey="3" onSelect={() => addFilter({ type: 3, data: { eventId: arb.match.id, bookie: arb.bets[0].bookie }})}>
                  Ocultar Evento en {arb.bets[0].bookie}
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" onSelect={() => addFilter({ type: 3, data: { eventId: arb.match.id, bookie: arb.bets[1].bookie }})}>
                  Ocultar Evento en {arb.bets[1].bookie}
                </Dropdown.Item>
                <Dropdown.Item eventKey="5" onSelect={() => addFilter({ type: 4, data: { eventId: arb.match.id, bookies: [arb.bets[0].bookie, arb.bets[1].bookie]  }})}>
                  Ocultar Evento en {arb.bets[0].bookie}/{arb.bets[1].bookie}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
              <CalculatorButton className="btn" onClick={() => calculator.enableCalculator(!calculator.calculatorEnabled)}>
                <i className="fas fa-calculator"/>
              </CalculatorButton>
          </Match>
      </MatchOverview>
      <MatchStatusOverview>
          <FlexContainer>
            { !pre && event.startedAt && <StartTimeBadge className="badge">{new Date(event.startedAt).getHours().toLocaleString("en-US", { minimumIntegerDigits: 2 })}:{new Date(event.startedAt).getMinutes().toLocaleString("en-US", { minimumIntegerDigits: 2 })}</StartTimeBadge> }
            { pre && event.startedAt && <StartTimeBadge className="badge">{new Date(event.startedAt).toLocaleString()}</StartTimeBadge> }
            { !pre && (
              <>
                <ScoreBadge className="badge badge-secondary">{getScoreForArbs(event)}{ configuration?.showTimeSinceLastUpdate && <SecondsSinceLastUpdateText> | {getSecondsSinceLastScoreUpdate(event)}s</SecondsSinceLastUpdateText> }</ScoreBadge>
                { getSubScoreForArbs(event) && <SubScoreBadge className="badge badge-secondary">{getSubScoreForArbs(event)}</SubScoreBadge>}
                { checkIfScoreChanged(event) && 
                <GoalIconContainer className="badge badge-secondary">
                  <GoalIcon src={getGoalIcon(event.sport?.name)}/> 
                  <b>GOAL</b>
                </GoalIconContainer>
                }
              </>
            )}
            { getStatusInformation(event) && <StatusBadge className="badge badge-secondary"> {getStatusInformation(event)} { configuration?.showTimeSinceLastUpdate && <SecondsSinceLastUpdateText style={{ color: '#1d2d1d' }}> | {getSecondsSinceLastActionUpdate(event)}s</SecondsSinceLastUpdateText> } </StatusBadge>  } 
          </FlexContainer>
          {pre && calculator.calculatorEnabled &&
            <FlexContainer>
              <SelectBookieContainer>
                <p style={{ margin: 0 }}>{t("mode")}</p>
                <ButtonGroup aria-label="Bookies" style={{ width: '300px' }}>
                  <Button key="normal" variant="primary" onClick={() => calculator.setMode('normal')} active={calculator.mode === 'normal'} style={{ textTransform: 'capitalize' }}>Normal</Button>
                  <Button key="freebet" variant="primary" onClick={() => calculator.setMode('freebet')} active={calculator.mode === 'freebet'} style={{ textTransform: 'capitalize' }}>Freebet</Button>
                </ButtonGroup>
              </SelectBookieContainer>
            </FlexContainer>
          }
          {pre && calculator.calculatorEnabled && <FlexContainer>
            <SelectBookieContainer>
              <p style={{ margin: 0 }}>{t("mainBookie")}</p>
              <ButtonGroup aria-label="Bookies" style={{ width: '300px' }}>
                {
                  arb.bets.map((bet, index) => (
                    <Button key={index} variant="primary" onClick={() => calculator.setCalculatorSelectedBookie(bet.bookie)} active={(!calculator.calculatorSelectedBookie && index === 0) || calculator.calculatorSelectedBookie === bet.bookie} style={{ textTransform: 'capitalize' }}>{bet.bookie}</Button>
                  )
                  )
                }
              </ButtonGroup>
            </SelectBookieContainer>
          </FlexContainer>
          }
          <FlexContainer>
            {calculator.calculatorEnabled &&
              <InputStakeContainer>
                <p style={{ margin: '0' }}>{pre ? 'Stake' : 'Retorno'}</p>
                <InputGroup style={{ margin: 0, width: '100px' }}>
                  <FormControl
                    placeholder="Stake"
                    aria-label="Stake"
                    aria-describedby="stake-control"
                    value={calculator.inputReturn} onChange={onChangeHandler}
                  />
                  <InputGroup.Append >
                    <InputGroup.Text id="stake-control" style={{ background: '#111e12' }}>€</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                {isPlacerUser && 
                <>
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(50)}>50</StakeButton>  
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(100)}>100</StakeButton>  
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(150)}>150</StakeButton>  
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(200)}>200</StakeButton>  
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(250)}>250</StakeButton>  
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(300)}>300</StakeButton>  
                <StakeButton variant="primary" onClick={() => calculator.setInputReturn(350)}>350</StakeButton> 
                <PlaceBetButton onClick={() => executeArb({ targetWin: calculator.inputReturn, firstBetId: selectedFirstBetId })}>
                  <i className="fas fa-play"></i>
                </PlaceBetButton>
                <NavigateToArbButton variant="primary" onClick={() => navigateToArb()}>
                  <i className="fas fa-compass"></i>
                  </NavigateToArbButton>
                </>
                }
              </InputStakeContainer>
            }
          </FlexContainer>
      </MatchStatusOverview>
      </> }
      <BetCardTable>
        <Column small={true} className="firstRow" style={{ widht: '50px'}}>
          <RowContentContainer className="firstRow">
            <ReportButton onClick={reportArb}>
                <i className="fas fa-exclamation-triangle"/>
            </ReportButton>
            {( !pre || settings?.configuration?.mode === 'normal') && <ProfitBadgeText className="badge badge-secondary" style={{ color: getColorForChangedArb((arb.previousArbUpdate|| {}).rawProfit, arb.rawProfit) }}>
              {((1 - arb.rawProfit) * 100).toFixed(2)}%
            </ProfitBadgeText> }
            { pre && settings?.configuration?.mode === 'freebet' && <RatingBadgeText className="badge badge-secondary" style={{ color: getColorForChangedArb((arb.previousArbUpdate|| {}).rawProfit, arb.rawProfit)  }}>
              {(arb.rating * 100).toFixed(2)}%
            </RatingBadgeText>
            }
            {arb.middle > 0 && 
              <MiddleBadge className="badge badge-secondary">
                <MiddleBadgeText>Middle</MiddleBadgeText>
                <MiddleBadgeValue>{arb.middle}</MiddleBadgeValue>
              </MiddleBadge>
            }
            <ElapsedTimeText>
              {getDuration(pre ? new Date(arb.timestamp) : arb.internalTimestamp, currentTime)}
            </ElapsedTimeText>
            <StatusBadgeText className="badge badge-secondary">
            {getBetStatusInformation(arb)}
            </StatusBadgeText>
          </RowContentContainer>
        </Column>
        <Column small={true}>
          <RowContentContainer>
            { sortedBets.map((bet, idx) => 
              <BetCardRow key={bet.id} className="bookie" idx={idx} onClick={() => isPlacerUser && setSelectedFirstBetId(bet.id)}>
                <p>
                  <BookieName onClick={() => { !isPlacerUser && setShowBookieEventName(!showBookieEventName) }}>{cloneBookies[bet.bookie] || bet.bookie}</BookieName>
                  { !arb.overTimeMatch && <OverTimeBadge overTimeIncluded={bet.overTimeIncluded} className="badge badge-secondary">{bet.overTimeIncluded ? 'OT Included' : 'OT Not Included'}</OverTimeBadge>}
                </p>
                {pinned && <BookieEventName visible={isPlacerUser || showBookieEventName} onClick={() => { navigator.clipboard.writeText(bet.event.name) }}>{bet.event.name}</BookieEventName> }
                <div className="bookie-odds">
                  { renderBetBadge(bet, idx) }
                  { !Number.isNaN(getOddsDeviation(bet, idx)) &&
                    <OverlayTrigger 
                      placement="right"
                      overlay={
                        <Tooltip>
                          <SelectedBookieText>{cloneBookies[bet.bookie] || bookieToLabel[bet.bookie]}: {bet.odds.toFixed(2)}</SelectedBookieText>
                          <AverageText>Average: {getAverageOdds(bet, idx).toFixed(2)}</AverageText>
                          { (alternativeOdds[idx] || bet.alternativeOdds)
                          .filter(({ active, deleted }) => active && !deleted)
                          .sort((a, b) => b.odds - a.odds).map(odds => 
                                <AlternativeBookieText key={odds.bookie} style={{ fontWeight: odds.bookie === bet.bookie ? 'bold' : 'normal' }}>
                                  {cloneBookies[bet.bookie] || bookieToLabel[odds.bookie]}: {odds?.odds?.toFixed(2)}
                                </AlternativeBookieText>
                          )}
                        </Tooltip>
                      }
                    >
                      <CircleButton size="sm">i</CircleButton>
                    </OverlayTrigger>
                  }
                </div>
              </BetCardRow>) 
            }
          </RowContentContainer>
        </Column>
        <Column className="column">
          <RowContentContainer>
            {sortedBets.map((bet, idx) => (
              <BetCardRow key={bet.id} small={true} idx={idx}>
                <BetName href={getURL(bet)} target="_blank" rel="noreferrer" className="market">{getBetNameFormatted(bet, arb)}</BetName>
              </BetCardRow>
            ))}
          </RowContentContainer>
        </Column>
        <Column small={true}>
          <RowContentContainer>
            {sortedBets.map((bet, idx) => (
              <BetCardRow key={bet.id} className="odds" small={true} idx={idx}>
                <BetOdds color={getColorForChangedArb(bet.odds, ((arb.previousArbUpdate|| {}).bets || [])[idx]?.odds)}>{Math.round(bet.odds * 100) / 100}</BetOdds>
                {pre && getBetHistory(bet).length > 2 && <OverlayTrigger
                  placement="left"
                  overlay={
                    <HistoryTooltipContainer>
                      <HistoryTooltip betHistory={getBetHistory(bet)}/>
                    </HistoryTooltipContainer>
                  }
                >
                  <CircleButton size="sm"><i className="fa fa-history" aria-hidden="true"></i></CircleButton>
                </OverlayTrigger>
                }
              </BetCardRow>
            ))}
          </RowContentContainer>
        </Column>
        { pinned && calculator.calculatorEnabled &&
        <Column small={true}>
          <RowContentContainer>
            {sortedBets.map((bet, idx) => (
              <BetCardRow key={bet.id} className="returns" small={true} idx={idx} style={{ color: getColorForChangedArb(bet.odds, ((arb.previousArbUpdate|| {}).bets || [])[idx]?.odds), fontWeight: 'bold', background: isPlacerUser && selectedFirstBetId === bet.id ? '#B8DCEF' : 'transparent' }}>
                {isPlacerUser ?
                  <ValuebetButton onClick={() => handleValuebetClick(bet)} style={{ background: getValuebetButtonColor(bet) }}>
                    <i className="fas fa-play"></i>
                  </ValuebetButton> :
                  <BetStake onClick={() => { navigator.clipboard.writeText(calculator.getStakeForBet(arb, { bookie: bet.bookie, idx }).toFixed(2)) }}><i className="fa-solid fa-copy" />  {calculator.getStakeForBet(arb, { bookie: bet.bookie, idx }).toFixed(2)}€</BetStake>
                }
              </BetCardRow>
            ))}
          </RowContentContainer>
        </Column>
        }
        <PinColumn>
          {pinned &&
            <button type="button" className="close" aria-label="Close" style={{ float: "left", width: "50px" }} onClick={() => setPinnedArb(undefined)}>
            <span aria-hidden="true">&times;</span>
          </button>
          }
        </PinColumn>
      </BetCardTable>
    </BetCardContainer>
  );
});
