import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { usePrevious } from "react-use";
import socketIOClient from "socket.io-client";
import md5 from "crypto-md5";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import protobuf from "protobufjs";
import _groupBy from "lodash/groupBy";
import { useTranslation } from 'react-i18next';

import "./Arbs.styled";
import { NavBarMain, ArbEventCard, ArbCard } from "..";
import {
  UserBetsContainer,
  Container,
  SelectedEvent,
  AllArbsContainer,
  SelectedArbContainer,
  ConfigureButton,
  EventsContainer,
  SportIcon,
  ConnectionBadge,
  VisitorBadge,
  ReportButton,
} from "./Arbs.styled";
import { ConfigureArbsModal } from "../ConfigureArbsModal/ConfigureArbsModal";
import { EventsModal } from "../EventsModal/EventsModal";
import { Button, Alert } from "react-bootstrap";
import { useSettings, useEvents, useUser, useReport } from "../../hooks";
import { SettingsProvider } from "../../contexts";
import { getSubscriptionsForSocket, subscriptionName } from "../../utils/subscriptions";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { getSportIcon, getSportIconColor } from "../../utils/events";
import { ReportProvider } from "../../contexts";

const fpPromise = FingerprintJS.load({ monitoring: false });

let socket;

const root = await protobuf.load('/protobuf/event.proto');
const EventMessage = root.lookupType('betsmarterpackage.EventMessage');

const arbRoot = await protobuf.load('/protobuf/arb.proto');
const ArbMessage = arbRoot.lookupType('betsmarterpackage.ArbMessage');

const getId = (arb) => {
  const bets = arb.bets.sort((a, b) => a.bookie.localeCompare(b.bookie));
  const id = `${bets[0].bookie}-${bets[1].bookie}-${bets[0].internalTopic || bets[0].topic}-${bets[1].internalTopic || bets[1].topic}`;
  const md5Id = md5(id);

  return md5Id;
};

// Update to what bookies update is the socket subscribed to
const updateSocketSubscriptions = (previousSettings = {}, nextSettings = {}) => {
  const previousSubscriptions = getSubscriptionsForSocket(previousSettings?.configuration);
  const nextSubscriptions = getSubscriptionsForSocket(nextSettings?.configuration);

  const subscribe = nextSubscriptions.filter((topic) => !previousSubscriptions.includes(topic));
  const unsubscribe = previousSubscriptions.filter((topic) => !nextSubscriptions.includes(topic));

  if (socket?.connected) socket.send({ subscribe, unsubscribe });
};

const useCalculator = () => {
  const [inputReturn, setInputReturn] = useState(200);
  const [calculatorSelectedBookie, setCalculatorSelectedBookie] = useState(undefined);
  const [calculatorEnabled, enableCalculator] = useState(false);
  const [mode, setMode] = useState('normal');

  const getBet1 = (arb) => calculatorSelectedBookie && arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) ? arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) : arb.bets[0];
  const getBet2 = (arb) => calculatorSelectedBookie && arb.bets.find(bet => bet.bookie === calculatorSelectedBookie) ? arb.bets.find(bet => bet.bookie !== calculatorSelectedBookie) : arb.bets[1];

  const getReturnForBet1 = arb => inputReturn / getBet1(arb).odds;

  const getStakeForBet2 = arb => inputReturn / getBet2(arb).odds;

  const getStakeForBet = (arb, { bookie, idx }) => bookie === calculatorSelectedBookie || (!calculatorSelectedBookie && idx === 0) ? getReturnForBet1(arb) : getStakeForBet2(arb);

  return { inputReturn, setInputReturn, calculatorSelectedBookie, setCalculatorSelectedBookie, calculatorEnabled, enableCalculator, getBet1, getBet2, getReturnForBet1, getStakeForBet, getStakeForBet2, mode, setMode };
};

// Wrap into provider:
// - configuration provider to have access to configuration
// - report provider to have a single modal for all reports
export const Arbs = observer((props) => (
  <SettingsProvider type="live">
    <ReportProvider>
      <ArbsContent {...props} />
    </ReportProvider>
  </SettingsProvider>
));

const SPORT_ORDER = [
  "football",
  "basketball",
  "tennis",
  "americanFootball",
  "hockey",
  "baseball",
];

const ArbsContent = observer(({ history }) => {
  const { captureSnapshot, getEvents } = useEvents();
  const { settings } = useSettings();
  const previousSettings = usePrevious(settings);
  const configuration = settings?.configuration;
  const [arbs, setArbs] = useState({ arbs: {}, events: {} });
  const [eventFiltered, setEventState] = useState(undefined);
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [pinnedArbId, setPinnedArbId] = useState(undefined);
  const [events, setEvents] = useState("{}");
  const { getActiveSubscription, getExtensionConfiguration, navigateToArb, executeArb, executeValuebet } = useUser();
  const [subscriptionStatus, setSubscriptionStatus] = useState(undefined);
  const [connected, setConnected] = useState(false);
  const [filters, setFilters] = useState([]);
  const [visitorId, setVisitorId] = useState(undefined);
  const [tokenConfiguration, setTokenConfiguration] = useState(undefined);
  const [fingerprintDetected, setFingerprintDetected] = useState(false);
  const [showVisitorId, setShowVisitorId] = useState(false);
  const { openReportModal } = useReport();
  const calculator = useCalculator();
  const { t } = useTranslation();

  const pinnedArb = useMemo(() => {
    if (pinnedArbId === undefined) return undefined;
    const arb = arbs.arbs[pinnedArbId];
    if (!arb) return undefined;
    return arb.type === "added"
      ? { ...arb.data, previousArbUpdate: arb.previousArbUpdate }
      : { ...arb.data, ...arb.previousArbUpdate, deleted: true };
  }, [pinnedArbId, arbs]);

  // const setPinnedArb = useCallback((arb) => setPinnedArbId(arb?.id), [setPinnedArbId]);
  const setPinnedArb = useCallback((arb) => {
    const roles = subscriptionStatus?.roles || [];
    if (arb && roles.includes('placer')) {
      navigateToArb(arb)
    }
    setPinnedArbId(arb?.id)
  }, [setPinnedArbId, subscriptionStatus]);

  const createReport = useCallback(
    async (event) => {
      event.stopPropagation();
      openReportModal();
    },
    [openReportModal]
  );

  const setEvent = useCallback((event) => {
    if (!eventFiltered) setEventState(event);
  }, []);

  const addFilter = useCallback((filter) => setFilters((previousFilters) => [...previousFilters, filter]), []);

  const getStatus = (arb) => {
    if (!arbs.events[arb.match.id].score.totalGames) return true;

    const currentGame = arbs.events[arb.match.id].score.totalGames + 1;
    return arb.bets[0].period > currentGame;
  
  };

  const checkIfNextPeriod = useCallback((arb) => {
    const sports = {
      tennis: () => (arb.bets[0].periodTypeId === 2 ? arb.status.extra?.isNextGame && getStatus(arb) : true),
      default: () =>
        arb.status.extra?.isNextGame ||
        arb.status.extra?.isNextSet ||
        arb.status.extra?.isNextQuarter ||
        arb.status.extra?.isNextHalf,
    };

    const sportName = arb.match?.sport?.name;
    return sports[sportName] ? sports[sportName]() : sports.default();
  }, []);

  const checkIfNextPeriodStrict = useCallback((arb) => {
    const sports = {
      default: () =>
        arb.status.extra?.isNextGame ||
        arb.status.extra?.isNextSet ||
        arb.status.extra?.isNextQuarter ||
        arb.status.extra?.isNextHalf,
    };

    const sportName = arb.match?.sport?.name;
    return sports[sportName] ? sports[sportName]() : sports.default();
  }, []);

  const checkIfPaused = useCallback((arb) => arbs.events[arb.match.id].status.paused, [arbs]);
  const checkIfPausedStrict = useCallback((arb) => {
    const sports = {
      basketball: () =>
        arb.status.extra?.isHalfTime ||
        arb.status.extra?.isInTimeout ||
        arb.status.extra?.isAtQuarterStartCheck,
      default: () => arbs.events[arb.match.id].status.paused,
    };

    const sportName = arb.match?.sport?.name;
    return sports[sportName] ? sports[sportName]() : sports.default();
  }, []);

  const processFilter = useCallback((filter, arb) => {
    const filterTypes = {
      1: () => filter.data.id === arb.id,
      2: () => filter.data.eventId === arb.match.id,
      3: () =>
        filter.data.eventId === arb.match.id && [arb.bets[0].bookie, arb.bets[1].bookie].includes(filter.data.bookie),
      4: () =>
        filter.data.eventId === arb.match.id &&
        filter.data.bookies.every((bookie) => [arb.bets[0].bookie, arb.bets[1].bookie].includes(bookie)),
    };

    return filterTypes[filter.type] ? filterTypes[filter.type]() : false;
  }, []);

  const checkIfNeedsFiltering = useCallback(
    (arb) => !filters.some((filter) => processFilter(filter, arb)),
    [filters, processFilter]
  );

  // Return a function that sorts arbs depending on the configuration
  const arbsSortFunc = useMemo(() => {
    const sortBy = {
      timeAsc: (a, b) => b.internalTimestamp - a.internalTimestamp,
      timeDes: (a, b) => a.internalTimestamp - b.internalTimestamp,
      profitDes: (a, b) => a.rawProfit - b.rawProfit,
      profitAsc: (a, b) => b.rawProfit - a.rawProfit,
    };

    return sortBy[configuration?.sortBy] ?? sortBy.timeDes;
  }, [configuration?.sortBy]);

  const addedArbs = useMemo(
    () =>
      Object.values(arbs.arbs)
        .filter((arb) => arb.type === "added")
        .map((arb) => ({ ...arb.data, previousArbUpdate: arb.previousArbUpdate })),
    [arbs]
  );

  const bookiesConfigs = useMemo(() => 
    Object.entries(configuration?.bookiesConfigs ?? {})
      .reduce((t, [bookie, config]) => ({ ...t, [`${bookie.split(':')[0]}`]: config }), {}), 
    [configuration?.bookiesConfigs]);

  const subscriptionsConfigs = useMemo(() => 
    Object.entries(configuration?.subscriptionConfigs ?? {})
      .reduce((t, [subscription, config]) => {
        const subcriptionSplitted = subscription.split('-');
        const bookie1 = subcriptionSplitted[0].split(':')[0];
        const bookie2 = subcriptionSplitted[1].split(':')[0];

        return { ...t, [`${bookie1}-${bookie2}`]: config };
      }, {}),
    [configuration?.subscriptionConfigs]);

  const filteredArbs = useMemo(
    () =>
      addedArbs.filter((arb) => {
        const [bookie1, bookie2] = [arb.bets[0].bookie, arb.bets[1].bookie];
        const bookie1Config = bookiesConfigs[bookie1];
        const bookie2Config = bookiesConfigs[bookie2];

        const subscription = subscriptionName(bookie1, bookie2);
        let subscriptionConfig = subscriptionsConfigs[subscription];
        if (subscriptionConfig === undefined) subscriptionConfig = configuration;
        return (
          checkIfNeedsFiltering(arb) &&
          (eventFiltered ? arb.match.id === eventFiltered.id : true) &&
          arb.bets.every((bet) => configuration.bookies.some(bookie => bookie.includes(bet.bookie))) &&
          ((configuration.bookiesReference || []).length > 0
            ? arb.bets.some((bet) => configuration.bookiesReference.some(bookie => bookie.includes(bet.bookie)))
            : false) &&
          // filter by subscription (statements below)
          (subscriptionConfig.filterOverTimeMismatch === "true" || subscriptionConfig.filterOverTimeMismatch === true
            ? arb.overTimeMatch
            : true) &&
          (subscriptionConfig.nextPeriodSports
            ? subscriptionConfig.nextPeriodSports.includes(arb.match?.sport?.name)
              ? checkIfNextPeriod(arb)
              : true
            : true) &&
          (subscriptionConfig.pausedSports
            ? subscriptionConfig.pausedSports.includes(arb.match?.sport?.name)
              ? checkIfPaused(arb) || checkIfNextPeriod(arb)
              : true
            : true) &&
          (subscriptionConfig.pausedSportsStrict
            ? subscriptionConfig.pausedSportsStrict.includes(arb.match?.sport?.name)
              ? checkIfPausedStrict(arb) || checkIfNextPeriodStrict(arb)
              : true
            : true) &&
          new Date() - arb.internalTimestamp > parseInt(subscriptionConfig.minTime) * 1000 &&
          new Date() - arb.internalTimestamp < parseInt(subscriptionConfig.maxTime) * 1000 &&
          (arb.middle > 0 && (subscriptionConfig.middleSports || []).includes(arb.match?.sport?.name)
            ? true
            : 100 - arb.rawProfit * 100 >= (parseInt(subscriptionConfig.minPercentage) || 0)) &&
          100 - arb.rawProfit * 100 <= (parseInt(subscriptionConfig.maxPercentage) || 100) &&
          subscriptionConfig.sports.includes(arb.match?.sport?.name) &&
          arb.bets.some((bet) =>
            (subscriptionConfig.sportsMarkets[arb.match?.sport?.name] ?? []).includes(bet.marketShortName)
          ) &&
          !(subscriptionConfig.excludedLeagues || []).some((league) =>
            arb.match.league.name.toLowerCase().includes(league.toLowerCase())
          ) &&
          (bookie1Config ? (bookie1Config.minOdds <= arb.bets[0].odds && bookie1Config.maxOdds >= arb.bets[0].odds) : true) &&
          (bookie2Config ? (bookie2Config.minOdds <= arb.bets[1].odds && bookie2Config.maxOdds >= arb.bets[1].odds) : true)
        );
      }),
    [addedArbs, checkIfNeedsFiltering, checkIfPaused, checkIfNextPeriod, eventFiltered, configuration]
  );

  // array of matches obtained from grouping all arbs by its match id
  const matches = useMemo(() => {
    const matchesHash = _groupBy(filteredArbs, (arb) => arb.match.id);
    const _matches = Object.entries(matchesHash).map(([matchId, matchArbs]) => ({
      id: matchId,
      arbs: matchArbs.sort(arbsSortFunc),
    }));
    _matches.sort((matchA, matchB) => arbsSortFunc(matchA.arbs[0], matchB.arbs[0]));
    return _matches;
  }, [filteredArbs]);

  const matchesForPinnedArb = useMemo(
    () => (pinnedArb ? matches.filter((match) => match.id == pinnedArb.match.id) : []),
    [matches, pinnedArb]
  );

  // Inital stuff to do after first render
  useEffect(() => {
    checkIfTokenStillValid(history);

    // Use a function to use async/await syntax inside useEffect
    const onMounted = async () => {
      await Promise.all([
        getActiveSubscription().then((subscriptionStatus) => setSubscriptionStatus(subscriptionStatus)),
        fpPromise.then((fp) => fp.get()).then((result) => setVisitorId(result.visitorId)),
        getExtensionConfiguration().then((response) => setTokenConfiguration(response)),
      ]);
    };
    onMounted();
  }, []);

  // Do stuff once the settings are loaded
  useEffect(() => {
    // Do not run until settings are loaded
    if (!settings) return;
    // Do not run twice
    if (socket !== undefined) return;
    
    const websocketURL = subscriptionStatus?.status === "NON_EXISTING" ? "https://wsfree.betsmarter.app/" : "https://ws.betsmarter.app/";

    socket = socketIOClient(websocketURL, {
      transports: ["websocket"],
      auth: { token: `Bearer ${localStorage.jwt}` },
    });

    // Handling token expiration
    socket.on("connect_error", (error) => {
      if (error.message === "not_subscribed") {
        console.error("User not subscribed!");
      } else if (error.type === "TransportError") {
        console.error("Connection lost");
      } else if ((error.data || {}).type === "UnauthorizedError") {
        console.error("User token has expired");
      }
    });

    socket.on("connect", () => {
      setConnected(true);

      const performance = navigator?.connection || {};

      const subscriptions = getSubscriptionsForSocket(configuration);
      socket.send({ subscribe: subscriptions });
      socket.send({
        info: {
          id: visitorId,
          performance: { downlink: performance.downlink, effectiveType: performance.effectiveType },
        },
      });
    });

    socket.on("disconnect", () => {
      setConnected(false);
    });

    let updates = [];

    socket.on("added", (payload) => {
      const decoded = ArbMessage.decode(new Uint8Array(payload));
      updates.push({ type: "added", data: decoded });
    });

    socket.on("warning", (payload) => {
      if (payload.type === "fingerprint") {
        setFingerprintDetected(true);
      }
    });

    socket.on("removed", (payload) => {
      updates.push(payload);
    });

    socket.on("eventsUpdate", (payload) => {
      setEvents(JSON.stringify(payload.data));
    });

    socket.on("eventUpdate", (payload) => {
      const decoded = EventMessage.decode(new Uint8Array(payload));
      updates.push({ type: "eventUpdate", data: decoded });
    });

    // Process updates periodically to update arbs
    const processUpdates = () => {
      const updatesCopy = [...updates];
      updates.splice(0, updatesCopy.length); // clear array

      setArbs((previousArbs) => {
        const newArbs = { ...previousArbs };
        updatesCopy.forEach(({ type, data }) => {
          try {
            if (type === "added" || type === "deleted") {
              const id = getId(data);
              const timestamp = new Date(data.timestamp);

              const isLatestUpdate = newArbs.arbs[id] ? timestamp > newArbs.arbs[id].timestamp : true;

              if (isLatestUpdate) {
                if ((newArbs.arbs[id] || {}).type === "deleted" && type === "deleted") {
                  newArbs.arbs[id] = { ...newArbs.arbs[id], timestamp };
                } else {
                  const internalTimestamp = new Date();
                  const previousArbUpdate =
                    (newArbs.arbs[id] || {}).type === "added"
                      ? newArbs.arbs[id].data
                      : (newArbs[id] || {}).previousArbUpdate;
                  newArbs.arbs[id] = { type, data: { ...data, id, internalTimestamp }, timestamp, previousArbUpdate };
                }
              }

              if (type === "added") {
                newArbs.events[data.match.id] = { ...data.match, status: data.status };
              }
            } else if (type === "eventUpdate") {
              newArbs.events[data.id] = data;
            } 
        } catch (error) {
          console.error(error);
        }
        });
        return newArbs;
      });

      window.setTimeout(processUpdates, 500);
    }

    processUpdates();

    // Fetch events
    getEvents().then((events) => setEvents(JSON.stringify(events)));
  }, [settings]);

  // Update subscriptions when there are new settings
  useEffect(() => {
    updateSocketSubscriptions(previousSettings, settings);
  }, [settings]);

  return (
    <>
      <UserBetsContainer>
        <NavBarMain currentPage="arbs" history={history} />
        {subscriptionStatus?.status === "FREEZED" && (
          <Alert variant="info" className="customAlert">
            <p>Tu cuenta se encuentra congelada por lo que no tienes acceso.</p>
          </Alert>
        )}
        {subscriptionStatus?.status === "NON_EXISTING" && (
          <Alert variant="warning" className="customAlert">
            <p>
              Actualmente solo tienes acceso a la suscripción gratuita.
            </p>
          </Alert>
        )}
        {fingerprintDetected && (
          <Alert variant="danger" className="customAlert">
            <p>
              Te hemos desconectado ya que has intentado acceder desde 2 navegadores distintos. Para volver a conectarte
              sin problemas espera 60 segundos. Para cualquier duda contáctanso en info@betsmarter.app
            </p>
          </Alert>
        )}
        {configuration?.bookiesReference?.length === 0 && (
          <Alert variant="danger" className="customAlert">
            <p>No tienes ninguna bookie de referencia seleccionada. ¡Modifica tu configuración!</p>
          </Alert>
        )}
        <rn-banner></rn-banner>
        <div style={{ display: "flex", alignItems: "center", marginTop: '20px' }}>
          <ConfigureButton
            disabled={!(connected && configuration)}
            onClick={() => setShowConfigurationModal(true)}
          >
            {t("configure")}
          </ConfigureButton>
          {Object.values(JSON.parse(events)).length > 0 && (
            <EventsContainer onClick={() => setShowEventModal(true)}>
              {Object.entries(JSON.parse(events))
                .sort(([sportA], [sportB]) => SPORT_ORDER.indexOf(sportA) - SPORT_ORDER.indexOf(sportB))
                .map(([sport, events]) => (
                  <a key={sport} style={{ marginRight: "10px", textTransform: "Capitalize", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <SportIcon className="sport" src={getSportIcon(sport)} filter={getSportIconColor(sport)} />
                    <span className="badgeCustom badge badge-light">{events.length}</span>
                  </a>
                ))}
            </EventsContainer>
          )}
          <ConnectionBadge connected={connected} onClick={() => setShowVisitorId(!showVisitorId)}>
            {connected ? t("connected") : t("notConnected")}
          </ConnectionBadge>
          <ReportButton onClick={createReport}>
            <span className="fas fa-exclamation-triangle"></span>
            <a>Reportar</a>
          </ReportButton>
          {showVisitorId && <VisitorBadge>{visitorId}</VisitorBadge>}
        </div>

        {configuration && (
          <>
            {eventFiltered && (
              <SelectedEvent>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  style={{ float: "left", marginRight: "5px" }}
                  onClick={() => setEventState(undefined)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                Evento seleccionado:
                <span className="badge badge-secondary" style={{ fontSize: "medium" }}>
                  {eventFiltered.name}
                </span>
              </SelectedEvent>
            )}
            <ConfigureArbsModal show={showConfigurationModal} setVisible={setShowConfigurationModal} />
            <EventsModal show={showEventModal} setVisible={setShowEventModal} events={JSON.parse(events)} />
            <Container>
              <AllArbsContainer pinnedArb={!!pinnedArb}>
                {matches.map((match) => (
                  <ArbEventCard
                    key={match.id}
                    configuration={configuration}
                    arbs={match.arbs}
                    event={arbs.events[match.id]}
                    captureSnapshot={captureSnapshot}
                    setEvent={setEvent}
                    setPinnedArb={setPinnedArb}
                    eventFiltered={eventFiltered}
                  />
                ))}
              </AllArbsContainer>
              {pinnedArb && (
                <SelectedArbContainer pinnedArb={!!pinnedArb}>
                  <ArbCard
                    arb={pinnedArb}
                    event={arbs.events[pinnedArb.match.id]}
                    captureSnapshot={captureSnapshot}
                    pinned={true}
                    setPinnedArb={setPinnedArb}
                    addFilter={addFilter}
                    calculator={calculator}
                    tokenConfiguration={tokenConfiguration}
                    configuration={configuration}
                    executeArb={(extra) => executeArb({ ...pinnedArb, ...extra })}
                    executeValuebet={(extra) => executeValuebet({ ...pinnedArb, ...extra })}
                    navigateToArb={() => navigateToArb(pinnedArb)}
                    subscriptionStatus={subscriptionStatus}
                  />
                  {matchesForPinnedArb.map((match) => (
                    <ArbEventCard
                      key={match.id}
                      configuration={configuration}
                      arbs={match.arbs}
                      event={arbs.events[match.id]}
                      captureSnapshot={captureSnapshot}
                      setEvent={setEvent}
                      setPinnedArb={setPinnedArb}
                      eventFiltered={true}
                    />
                  ))}
                </SelectedArbContainer>
              )}
            </Container>
          </>
        )}
      </UserBetsContainer>
    </>
  );
});
