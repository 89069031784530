import React, { useEffect, useState } from "react";
import { NavBarMain } from "..";
import "./AddBookie.styled";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { bookieForm, editBookieForm } from "../../schemas/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUser } from "../../hooks";
// import { getErrorMessage } from '../../utils/bookies'
import { PageContainer, BookieInfoContainer, Button } from "./AddBookie.styled";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { useUsers } from "../../contexts/UsersContext";
import { useTranslation } from "react-i18next";

const bookiesWithBirthdate = ['winamax'];
const bookiesWithEmail = ['tonybet'];

export const AddBookie = ({ history, match }) => {
  const { addBookie } = useUser()
  const { mutate } = useUsers();
  const { t } = useTranslation();
  // const [error, setError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);

  useEffect(() => {
    checkIfTokenStillValid(history)
  }, [])

  const getAction = () => match.params.action;

  const handleAddBookie = async values => {
    const { email, bookie, action } = match.params;
    const { username, password, enable, date, birthdate } = values;
    try {
      await addBookie({ email, bookie, action, username, password, enable, date, birthdate }).then(response => response.ok && history.push(`/bookies/${email}`));
      mutate();
    } catch (err) {
      // setError({ error: err.response.data ||  err.response.data.error });
      console.log(err)
    }
  };

  return (
    <PageContainer>
      <NavBarMain currentPage="bothome" history={history}/>
      <BookieInfoContainer>
        <Formik
          validationSchema={getAction() === 'add' ? bookieForm : editBookieForm}
          initialValues={getAction() === 'add' ? { username: "", password: "", enable: false } : { password: "", enable: false }}
          onSubmit={async values => handleAddBookie(values)}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="username">
                <Form.Label>{t(bookiesWithEmail.includes(match.params.bookie) ? "Email" : "Usuario")}</Form.Label>
                <Form.Control
                  type={bookiesWithEmail.includes(match.params.bookie) ? "email" : "text"}
                  placeholder={bookiesWithEmail.includes(match.params.bookie) ? "Email" : "Usuario"}
                  value={values.username}
                  onChange={handleChange}
                  isValid={touched.username && !errors.username}
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>{t("Password")}</Form.Label>
                <Form.Control
                  placeholder={t("Password")}
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                />
                <Form.Text style={{ color: "#758B78" }}>
                  {t("passwordSecure")}
                </Form.Text>
              </Form.Group>
              {bookiesWithBirthdate.includes(match.params.bookie) && <Form.Group controlId="birthdate">
                <Form.Label>{t("birthdate")}</Form.Label>
                <Form.Control
                  type="date"
                  value={values.birthdate}
                  onChange={handleChange}
                  isValid={touched.birthdate && !errors.birthdate}
                />
              </Form.Group>
              }
              <Form.Group controlId="enableControl">
                <Form.Label>{t("enable")}</Form.Label>
                <Form.Control
                  as="select"
                  value={values.enableControl}
                  onChange={val => {
                    handleChange(val);
                    if (val.nativeEvent.target.selectedIndex === 1) {
                      setFieldValue("enable", true);
                      setShow(false);
                    } else if (val.nativeEvent.target.selectedIndex === 2) {
                      setFieldValue("enable", false);
                      setShow(true);
                    } else {
                      setFieldValue("enable", false);
                      setShow(false);
                    }
                  }}
                  label="Activar"
                >
                  <option>{t("noEnable")}</option>
                  <option>{t("enableNow")}</option>
                  <option>{t("scheduleEnable")}</option>
                </Form.Control>
              </Form.Group>
              {show && (
                <Form.Group controlId="enable">
                  <Form.Label>{t("activationDate")}</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => {
                      setFieldValue("date", date);
                      setStartDate(date.getTime());
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                  />
                </Form.Group>
              )}
              <Button type="submit" className="btn-secondary" >
                {getAction() === "add" ? t("add") : t("edit")}
              </Button>
            </form>
          )}
        </Formik>
      </BookieInfoContainer>
    </PageContainer>
  );
};
